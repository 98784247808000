import { HeaderComponent } from '@Interfaces'

export const HeaderItems: HeaderComponent.Item[] = [
  {
    icon: 'home',
    title: 'Home',
    href: '/projects',
    as: '/projects',
  },
  {
    icon: 'building',
    title: 'Projectgegevens',
    href: '/projects/[projectId]',
    as: '/projects/{{id}}',
  },
  {
    icon: 'list',
    title: 'Locatie en artikelen',
    href: '/projects/[projectId]/locations',
    as: '/projects/{{id}}/locations',
  },
  {
    icon: 'list',
    title: `Foto's`,
    href: '/projects/[projectId]/photos',
    as: '/projects/{{id}}/photos',
  },
  {
    icon: 'people',
    title: 'Gebruikersbeheer',
    href: '/projects/[projectId]/users',
    as: '/projects/{{id}}/users',
  },
]
