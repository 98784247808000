import { HYDRATE } from 'next-redux-wrapper'

import { ActionConsts } from '@Definitions'
import { IAction, IResourcesStore } from '@Interfaces'
import { parseResource } from '@Services'

export const INITIAL_STATE: IResourcesStore.InitialState = {}

const resourceTemplate = {
  isCreating: false,
  isReading: false,
  items: [],
  error: '',
}

export const ResourcesReducer = (state = INITIAL_STATE, action: IAction<any>) => { // eslint-disable-line
  const { resource, items, error } = action.payload || ({} as any) // eslint-disable-line
  const currentResource = state[resource] || resourceTemplate

  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.resources,
      }

    case ActionConsts.Resources.ReadResourceStart:
      return {
        ...state,
        [resource]: {
          ...currentResource,
          items: [],
          isReading: true,
          error: undefined,
        },
      }

    case ActionConsts.Resources.ReadResourceSuccess:
      return {
        ...state,
        [resource]: {
          ...currentResource,
          isReading: false,
          items: parseResource(resource, items),
        },
      }

    case ActionConsts.Resources.ReadResourceFailure:
      return {
        ...state,
        [resource]: {
          ...currentResource,
          isReading: false,
          error,
        },
      }

    case ActionConsts.Resources.ResetReducer:
      return INITIAL_STATE

    default:
      return state
  }
}
