import moment from 'moment'
import { IResourcesStore, NTAB } from '@Interfaces'

export type TResource = Project | Photo | User | ValuationType

export const parseResource = ( // eslint-disable-line
  resource: string,
  items: IResourcesStore.ResourceItem[] | any,
  opts?: any,
): TResource[] | any => {
  if (resource === 'validation') {
    return items
  }

  const resources = []

  for (let i = 0; i < items.length; i++) {
    const item = items[i]

    switch (resource) {
      case 'projects':
        resources.push(
          new Project( // eslint-disable-line
            item.id,
            item.name,
            item.valuationDate,
            item.projectNumber,
            item.address,
            item.zipcode,
            item.city,
            item.client_Name,
            item.client_Contact,
            item.client_Address,
            item.client_Zipcode,
            item.client_City,
            item.valuationTypes,
            item.users,
            item.notesOnValuation,
            item.notesOnValuation_EN,
            item.remarks,
            item.remarks_EN,
            item.photos,
          ),
        )
        break
      case 'users':
        resources.push(
          new User( // eslint-disable-line
            item.id,
            item.name,
            item.abbreviation,
            item.secretariat,
            item.email,
            item.signature_Url,
          ),
        ) // eslint-disable-line
        break
      case 'valuationTypes':
        resources.push(new ValuationType(item.id, item.name)) // eslint-disable-line
        break
      case 'locations':
        resources.push(new Location(item.id, item.name, item.name_EN, item.childs, opts ? opts.level : 0)) // eslint-disable-line
        break
      case 'items':
        resources.push(
          new Item( // eslint-disable-line
            item.id,
            item.locationId,
            item.categoryId,
            item.amount,
            item.name,
            item.name_EN,
            item.type,
            item.description,
            item.description_EN,
            item.yearOfConstruction,
            item.vatId,
            item.mileage,
            item.licensePlate,
            item.sequenceNumber,
            item.brand,
            item.proMemorie,
            item.values,
            item.vat,
            item.visualInspectionReserved,
            item.categorie,
            item.photos,
          ),
        )
        break
      case 'photos':
        resources.push(
          new Photo( // eslint-disable-line
            item.id,
            item.description,
            item.description_EN,
            item.itemId,
            item.itemCategory,
            item.itemDescription,
            item.exportToReport,
            item.url_Original,
            item.url_Thumb,
            item.url_Medium,
          ),
        )
        break
      case 'categories':
        resources.push(
          new Category( // eslint-disable-line
            item.id,
            item.name,
            item.name_EN,
            item.key,
          )
        )
        break
      case 'vat':
        resources.push(new Vat(item.id, item.name)) // eslint-disable-line
        break
      default:
        resources.push(item)
        break
    }
  }

  return resources
}

export class Project implements NTAB.Project {
  id: number
  projectNumber: string
  name: string
  valuationDate: string
  address: string
  zipcode: string
  city: string
  clientName: string
  clientContact: string
  clientAddress: string
  clientZipcode: string
  clientCity: string
  valuationTypes: ValuationType[]
  users: User[]
  notesOnValuation: string
  notesOnValuationEN: string
  remarks: string
  remarksEN: string
  photos: Photo[]

  constructor(
    id: number = 0,
    name: string = '',
    valuationDate: string = '',
    projectNumber: string = '',
    address: string = '',
    zipcode: string = '',
    city: string = '',
    clientName: string = '',
    clientContact: string = '',
    clientAddress: string = '',
    clientZipcode: string = '',
    clientCity: string = '',
    valuationTypes: NTAB.ValuationType[] = [],
    users: NTAB.User[] = [],
    notesOnValuation: string = '',
    notesOnValuationEN: string = '',
    remarks: string = '',
    remarksEN: string = '',
    photos: NTAB.Photo[],
  ) {
    this.id = id
    this.projectNumber = projectNumber
    this.name = name
    this.valuationDate = valuationDate !== '' ? moment(valuationDate).format('DD-MM-YYYY') : ''
    this.address = address
    this.zipcode = zipcode
    this.city = city
    this.clientName = clientName
    this.clientContact = clientContact
    this.clientAddress = clientAddress
    this.clientZipcode = clientZipcode
    this.clientCity = clientCity
    this.valuationTypes = valuationTypes
      ? (parseResource('valuation-types', valuationTypes) as ValuationType[])
      : []
    this.users = users ? (parseResource('users', users) as User[]) : []
    this.notesOnValuation = notesOnValuation
    this.notesOnValuationEN = notesOnValuationEN
    this.remarks = remarks
    this.remarksEN = remarksEN
    this.photos = photos ? (parseResource('photos', photos) as Photo[]) : []
  }
}

export class User implements NTAB.User {
  id: number
  name: string
  abbreviation: string
  secretariat: boolean
  email: string
  signatureUrl: string

  constructor(
    id: number = 0,
    name: string = '',
    abbreviation: string = '',
    secretariat: boolean = false,
    email: string = '',
    signatureUrl: string = '',
  ) {
    this.id = id
    this.name = name
    this.abbreviation = abbreviation
    this.secretariat = secretariat
    this.email = email
    this.signatureUrl = signatureUrl
  }
}

export class ValuationType implements NTAB.ValuationType {
  id: number
  name: string

  constructor(id: number = 0, name: string = '') {
    this.id = id
    this.name = name
  }
}

export class Location implements NTAB.Location {
  id: number
  name: string
  nameEN: string
  level: number
  childs?: Location[]

  constructor(
    id: number = 0,
    name: string = '',
    nameEN: string = '',
    childs: NTAB.Location[] = [],
    level: number = 0,
  ) {
    this.id = id
    this.name = name
    this.nameEN = nameEN
    this.level = level

    if (childs) {
      this.childs = parseResource('locations', childs, { level: level + 1 }) as Location[]
    }
  }
}

export class Item implements NTAB.Item {
  id: number
  locationId: number
  categoryId: number
  amount: number | string
  name: string
  nameEN: string
  type: string
  description: string
  descriptionEN: string
  yearOfConstruction: number | string
  vatId: number
  mileage: number
  licensePlate: string
  sequenceNumber: number
  brand: string
  proMemorie: boolean
  values: NTAB.ItemValue[]
  vat: string
  visualInspectionReserved: boolean
  categorie: string
  photos: Photo[]

  constructor(
    id: number = 0,
    locationId: number = 0,
    categoryId: number = 0,
    amount: number | string = '',
    name: string = '',
    nameEN: string = '',
    type: string = '',
    description: string = '',
    descriptionEN: string = '',
    yearOfConstruction: number | string = '',
    vatId: number = 0,
    mileage: number = 0,
    licensePlate: string = '',
    sequenceNumber: number = 0,
    brand: string = '',
    proMemorie: boolean = false,
    values: NTAB.ItemValue[] = [],
    vat: string = '',
    visualInspectionReserved: boolean = false,
    categorie: string = '',
    photos: NTAB.Photo[] = [],
  ) {
    this.id = id
    this.locationId = locationId
    this.categoryId = categoryId
    this.amount = amount
    this.name = name
    this.nameEN = nameEN
    this.type = type
    this.description = description
    this.descriptionEN = descriptionEN
    this.yearOfConstruction = yearOfConstruction
    this.vatId = vatId
    this.mileage = mileage
    this.licensePlate = licensePlate
    this.sequenceNumber = sequenceNumber
    this.brand = brand
    this.proMemorie = proMemorie
    this.values = values
    this.vat = vat
    this.visualInspectionReserved = visualInspectionReserved
    this.categorie = categorie
    this.photos = parseResource('photos', photos)
  }
}

export class Photo implements NTAB.Photo {
  id: number
  description: string
  descriptionEN: string
  itemId: number
  itemCategory: string
  itemDescription: string
  exportToReport: boolean
  urlOriginal: string
  urlThumb: string
  urlMedium: string
  buffer: string

  constructor(
    id: number = 0,
    description: string = '',
    descriptionEN: string = '',
    itemId: number = 0,
    itemCategory: string = '',
    itemDescription: string = '',
    exportToReport: boolean = false,
    urlOriginal: string = '',
    urlThumb: string = '',
    urlMedium: string = '',
    buffer: string = '',
  ) {
    this.id = id
    this.description = description
    this.descriptionEN = descriptionEN
    this.itemId = itemId
    this.itemCategory = itemCategory
    this.itemDescription = itemDescription
    this.exportToReport = exportToReport
    this.urlOriginal = urlOriginal
    this.urlThumb = urlThumb
    this.urlMedium = urlMedium
    this.buffer = buffer
  }
}

export class Category implements NTAB.Category {
  id: number
  name: string
  nameEN: string
  key: string

  constructor(id: number = 0, name: string = '', nameEN: string = '', key: string = '') {
    this.id = id
    this.name = name
    this.nameEN = nameEN
    this.key = key
  }
}

export class Vat implements NTAB.Vat {
  id: number
  name: string

  constructor(id: number = 0, name: string = '') {
    this.id = id
    this.name = name
  }
}
