import { HYDRATE } from 'next-redux-wrapper'

import { ActionConsts } from '@Definitions'
import { IAction, IRuntimeStore } from '@Interfaces'
import { parseResource } from '@Services'

export const INITIAL_STATE: IRuntimeStore.IInitialState = {
  isAuthenticated: false,
  currentUser: null,
  currentProject: {
    data: null,
    loading: false,
  },
}

export const RuntimeReducer = (state = INITIAL_STATE, action: IAction<any>) => { // eslint-disable-line
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.runtime,
      }

    case ActionConsts.Runtime.SetCurrentProjectStart:
      return {
        ...state,
        currentProject: {
          loading: true,
          data: null,
          error: undefined,
        },
      }

    case ActionConsts.Runtime.SetCurrentProjectSuccess:
      return {
        ...state,
        currentProject: {
          loading: false,
          data: parseResource('projects', [action.payload.project])[0],
        },
      }

    case ActionConsts.Runtime.SetCurrentProjectFailure:
      return {
        ...state,
        currentProject: {
          loading: false,
          error: action.payload.error,
        },
      }

    case ActionConsts.Runtime.SetIsAuthenticated:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      }

    case ActionConsts.Runtime.SetCurrentUser:
      return {
        ...state,
        currentUser: action.payload.user,
      }

    case ActionConsts.Runtime.ResetReducer:
      return INITIAL_STATE

    default:
      return state
  }
}
