import getConfig from 'next/config'
import { MsalAuthProvider, LoginType } from 'react-aad-msal'

const {
  publicRuntimeConfig: { MSAL_AUTHORITY, MSAL_CLIENT_ID, MSAL_REDIRECT_URI },
} = getConfig()

// Msal Configurations
const config: any = {
  auth: {
    authority: MSAL_AUTHORITY,
    clientId: MSAL_CLIENT_ID,
    redirectUri: MSAL_REDIRECT_URI,
    postLogoutRedirectUri: MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

// Authentication Parameters
const authenticationParameters: any = {
  scopes: ['openid'],
}

// Options
const options: any = {
  loginType: LoginType.Popup,
}

// return a mock on the server side
export const authProvider = process.browser
  ? new MsalAuthProvider(config, authenticationParameters, options)
  : (({
      getAccountInfo: () => {},
      getError: () => {},
      getAccessToken: async () => '',
      getIdToken: async () => '',
    } as any) as MsalAuthProvider)
