import Router from 'next/router'
import moment from 'moment'
import { Http, Photo } from '@Services'
import { IHttp } from '@Interfaces'

export const NTABService = {
  Logout: async () => {
    NTABService.UnsetAuth()
    Router.replace('/')
  },
  SetAuth: (jwtIdToken: string) => {
    Http.SetAccessToken(jwtIdToken)

    if (typeof document !== 'undefined') {
      document.cookie = `jwtIdToken=${jwtIdToken}; path=/; samesite; secure`
    }
  },
  UnsetAuth: () => {
    Http.SetAccessToken('')

    if (typeof document !== 'undefined') {
      document.cookie =
        'jwtIdToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; samesite; secure'
    }
  },
  GetAuthorizedUser: async () => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('GET', '/rest/users/authorized')
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  ReadResource: async (
    resource: string,
    id?: number,
    relation?: string,
    relationId?: number,
  ): Promise<IHttp.Response> => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request(
        'GET',
        `/rest/${resource}${id ? `/${id}` : ''}${relation ? `/${relation}` : ''}${
          relationId ? `/${relationId}` : ''
        }`,
      )

      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  BulkUpdateItems: async (values: any) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/items/bulk', undefined, values)
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  SortItems: async (projectId: number, locationId: number, items: number[]) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/items/sort', undefined, {
        projectId,
        locationId,
        items,
      })

      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  CreateOrUpdateItem: async (
    categoryId: number,
    vatId: number | null,
    name: string,
    nameEN: string,
    amount: number | null,
    type: string,
    description: string,
    descriptionEN: string,
    yearOfConstruction: number,
    visualInspectionReserved: boolean,
    proMemorie: boolean,
    brand: string,
    licensePlate: string,
    mileage: string,
    values: any[],
    photos: any[],
    locationId: number,
    projectId: number,
    id?: number,
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request(
        id !== undefined && id > -1 ? 'PUT' : 'POST',
        '/rest/items',
        undefined,
        {
          id,
          categoryId,
          vatId,
          projectId,
          locationId,
          name,
          name_EN: nameEN,
          amount,
          type,
          description,
          description_EN: descriptionEN,
          yearOfConstruction,
          visualInspectionReserved,
          proMemorie,
          brand,
          licensePlate,
          mileage,
          values,
          photos,
        },
      )

      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  DeleteItem: async (id: number, projectId: number) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('DELETE', '/rest/items', undefined, { id, projectId })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  CreateProjectImage: async (projectId: number, photosToAdd: any[]) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/projects/photos', undefined, {
        projectId: parseInt(`${projectId}`, 10),
        photosToAdd,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  DeleteProjectImage: async (projectId: number, photoToDelete: number[]) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/projects/photos', undefined, {
        projectId,
        photoToDelete,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  AddLocation: async (name: string, nameEN: string, parentId: number | null, projectId: number) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/locations', undefined, {
        name,
        name_EN: nameEN,
        parentId,
        projectId,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateNotesOnValuation: async (projectId: number, value: string, language: 'en' | 'nl') => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/projects/notes-on-valuation', undefined, {
        projectId,
        notesOnValuation: value,
        language,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  DeleteLocation: async (id: number, projectId: number) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('DELETE', '/rest/locations', undefined, { id, projectId })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateRemarks: async (projectId: number, value: string, language: 'en' | 'nl') => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/projects/remarks', undefined, {
        projectId,
        remarks: value,
        language,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  GetBrands: async (query: string) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/brands', undefined, { name: query, qty: 10 })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  ExportReport: async (
    projectId: number,
    showIndividualValues: boolean,
    isAssessmentReport: boolean,
    isValuationReport: boolean,
    valuationTypes: number[],
    valuatorInitials: string,
    signerUserId: number,
    locationId: number,
    photoAttachment: boolean,
    isControlVersion: boolean,
    language: string,
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/reports', undefined, {
        projectId,
        showIndividualValues,
        isAssessmentReport,
        isValuationReport,
        valuationTypes,
        valuatorInitials,
        signerUserId,
        locationId,
        photoAttachment,
        isControlVersion,
        language,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  CreateUser: async (
    name: string,
    abbreviation: string,
    email: string,
    password: string,
    passwordConfirm: string,
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/users', undefined, {
        name,
        abbreviation,
        email,
        password,
        passwordConfirm,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateUser: async (
    id: number,
    abbreviation: string,
    deleteSignature: boolean,
    isSecretariat: boolean,
    signature: {
      buffer: string
      mimeType: string
    },
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/users', undefined, {
        id,
        abbreviation,
        signature: signature.buffer ? signature : undefined,
        deleteSignature,
        isSecretariat,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  ImportCSV: async (projectId: number, type: string, buffer: string, mimeType: string) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/imexport/import', undefined, {
        projectId,
        type,
        buffer,
        mimeType,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  ExportCSV: async (projectId: number, type: string) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/imexport/export', undefined, {
        projectId,
        type,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateProjectPhoto: async (
    projectId: number,
    projectPhotoId: number,
    description: string,
    description_EN: string, // eslint-disable-line
    exportToReport: boolean,
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/projects/photo/details', undefined, {
        projectId,
        projectPhotoId,
        description,
        description_EN,
        exportToReport,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  CreateCategory: async (projectId: number, name: string, name_EN: string) => { // eslint-disable-line
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/categories', undefined, {
        projectId,
        name,
        name_EN,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateLocation: async (projectId: number, locationId: number, name: string, nameEN: string) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/locations', undefined, {
        projectId,
        id: locationId,
        name,
        name_EN: nameEN,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  AddAndDeleteItemPhotos: async (
    projectId: number,
    itemId: number,
    photosToAdd: Photo[],
    photoToDelete: number[],
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/items/photos', undefined, {
        projectId,
        itemId,
        photosToAdd,
        photoToDelete,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateItemPhoto: async (
    projectId: number,
    itemId: number,
    itemPhotoId: number,
    description: string,
    description_EN: string, // eslint-disable-line
    exportToReport: boolean,
  ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/items/photo/details', undefined, {
        projectId,
        itemId,
        itemPhotoId,
        description,
        description_EN,
        exportToReport,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  ReorderLocations: async (projectId: number, parentId: number | null, locations: number[]) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/locations/sort', undefined, {
        projectId,
        parentId,
        locations,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  SearchProject: async (projectNumber: string, name: string) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('POST', '/rest/projects/find', undefined, {
        projectNumber,
        name,
      })
      response.success = true
      response.data = data.map((x: any) => ({
        ...x,
        valuationDate: moment(x.valuationDate).format('DD-MM-YYYY'),
      }))
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  GetValuationTypes: async () => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('GET', '/rest/valuation-types')
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }

    return response
  },
  UpdateProjectValuationTypes: async (
    Id: number,
    ValuationTypes: number[],
    ) => {
    const response: IHttp.Response = {
      success: false,
    }

    try {
      const data = await Http.Request('PUT', '/rest/projects/valuation-types', undefined, {
        Id,
        ValuationTypes,
      })
      response.success = true
      response.data = data
    } catch (e) {
      response.error = e.message
    }
  
      return response
    },
}
