import { ActionConsts } from '@Definitions'
import { NTAB } from '@Interfaces'
import { User } from '@Services'

export const RuntimeActions = {
  SetCurrentProjectStart: (id: number) => ({
    type: ActionConsts.Runtime.SetCurrentProjectStart,
    payload: {
      id,
    },
  }),

  SetCurrentProjectSuccess: (project: NTAB.Project) => ({
    type: ActionConsts.Runtime.SetCurrentProjectSuccess,
    payload: {
      project,
    },
  }),

  SetCurrentProjectFailure: (error: string = 'Er ging iets fout, probeer het opnieuw.') => ({
    type: ActionConsts.Runtime.SetCurrentProjectFailure,
    payload: {
      error,
    },
  }),

  SetIsAuthenticated: (isAuthenticated: boolean) => ({
    type: ActionConsts.Runtime.SetIsAuthenticated,
    payload: {
      isAuthenticated,
    },
  }),

  SetCurrentUser: (user: User | null) => ({
    type: ActionConsts.Runtime.SetCurrentUser,
    payload: {
      user,
    },
  }),

  Logout: () => ({
    type: ActionConsts.Runtime.Logout,
  }),

  Reset: () => ({
    type: ActionConsts.Runtime.ResetReducer,
  }),

  RefreshToken: () => ({
    type: ActionConsts.Runtime.RefreshToken,
  }),
}
