import 'isomorphic-unfetch'
import getConfig from 'next/config'
import { stringify } from 'query-string'
import formurlencoded from 'form-urlencoded'

import { NTABService } from '@Services'
import { IHttp } from '@Interfaces'
import { authProvider } from '@Providers'

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

export const Http = {
  accessToken: '',
  language: 'nl-NL',
  needRefresh: false,

  SetAccessToken: (token: string) => {
    Http.accessToken = token
  },

  SetLanguage: (lang: string) => {
    Http.language = lang
  },

  SetNeedRefresh: (needRefresh: boolean) => {
    Http.needRefresh = needRefresh
  },

  Request: async (
    methodType: string,
    endpoint: string,
    queryParams?: IHttp.RequestQueryParams,
    payload?: IHttp.RequestPayload,
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const query = queryParams ? `?${stringify(queryParams)}` : ''

      const settings: any = {
        method: `${methodType}`,
        cache: 'no-cache',
        headers: {
          'content-type':
            endpoint === '/token' ? 'application/x-www-form-urlencoded' : 'application/json',
        },
      }

      const providerToken = await authProvider.getIdToken()

      if (providerToken && providerToken.idToken.rawIdToken !== Http.accessToken) {
        Http.SetAccessToken(providerToken.idToken.rawIdToken)
        NTABService.SetAuth(providerToken.idToken.rawIdToken)
      }

      if (Http.accessToken) {
        settings.headers.Authorization = `Bearer ${Http.accessToken}`
      }

      if (payload) {
        if (endpoint === '/token') {
          settings.body = formurlencoded(payload)
        } else {
          settings.body = JSON.stringify(payload)
        }
      }

      fetch(`${API_URL}${endpoint}${query}`, settings)
        .then(async (response: any) => {
          if (response.ok) {
            if (endpoint === '/rest/imexport/export') {
              const blob = await response.blob()
              const link = document.createElement('a')
              link.setAttribute('download', `${(payload as any).type}.csv`)
              link.href = window.URL.createObjectURL(blob)
              document.body.appendChild(link)
              link.click()
              link.remove()

              return resolve({})
            }

            return response
              .text()
              .then((data: string) => { // eslint-disable-line
                return data ? JSON.parse(data) : {}
              })
              .then(resolve)
          }

          return reject(new Error(response.statusText))
        })
        .catch(e => {
          reject(e)
        })
    })
  },
}
