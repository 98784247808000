import { IRuntimeStore } from '@Interfaces'
import { INITIAL_STATE } from '@Redux/runtime/reducer'

export const getCurrentProject = (state: IRuntimeStore.IInitialState = INITIAL_STATE) => {
  return state.currentProject
}

export const getIsAuthenticated = (state: IRuntimeStore.IInitialState = INITIAL_STATE) => {
  return state.isAuthenticated
}

export const getCurrentUser = (state: IRuntimeStore.IInitialState = INITIAL_STATE) => {
  return state.currentUser
}
