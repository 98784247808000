import { ActionConsts } from '@Definitions'
import { IResourcesStore } from '@Interfaces'

export const ResourcesActions = {
  ReadResourceStart: (resource: string, id?: number, relation?: string, relationId?: number) => ({
    type: ActionConsts.Resources.ReadResourceStart,
    payload: {
      resource,
      id,
      relation,
      relationId,
    },
  }),

  ReadResourceSuccess: (resource: string, items: IResourcesStore.ResourceItem[]) => ({
    type: ActionConsts.Resources.ReadResourceSuccess,
    payload: {
      resource,
      items,
    },
  }),

  ReadResourceFailure: (resource: string, error: string) => ({
    type: ActionConsts.Resources.ReadResourceFailure,
    payload: {
      resource,
      error,
    },
  }),

  Reset: () => ({
    type: ActionConsts.Resources.ResetReducer,
  }),
}
