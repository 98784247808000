export const ActionConsts = {
  Runtime: {
    ResetReducer: '@@runtime/ResetReducer',
    SetCurrentProjectStart: '@@runtime/SetCurrentProjectStart',
    SetCurrentProjectSuccess: '@@runtime/SetCurrentProjectSuccess',
    SetCurrentProjectFailure: '@@runtime/SetCurrentProjectFailure',
    Logout: '@@runtime/Logout',
    SSOLoginSuccess: 'AAD_LOGIN_SUCCESS',
    SetIsAuthenticated: '@@runtime/SetIsAuthenticated',
    SetCurrentUser: '@@runtime/SetCurrentUser',
    RefreshToken: '@@runtime/RefreshToken',
  },
  Resources: {
    ResetReducer: '@@resources/ResetReducer',
    CreateResourceStart: '@@resources/CreateResourceStart',
    CreateResourceSuccess: '@@resources/CreateResourceSuccess',
    CreateResourceFailure: '@@resources/CreateResourceFailure',
    ReadResourceStart: '@@resources/ReadResourceStart',
    ReadResourceSuccess: '@@resources/ReadResourceSuccess',
    ReadResourceFailure: '@@resources/ReadResourceFailure',
    UpdateResourceStart: '@@resources/UpdateResourceStart',
    UpdateResourceSuccess: '@@resources/UpdateResourceSuccess',
    UpdateResourceFailure: '@@resources/UpdateResourceFailure',
  },
}
